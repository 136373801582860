<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="收藏" :rightData="rightData" />
    <van-list v-model="loading" :finished="finish" @load="getGoodsCollectList" loading-text="加载中..." finished-text="" error-text="加载失败">
      <div v-if="finish && !loading && goodsList.length == 0" class="empty-item">
        <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
      </div>
      <div v-else class="goods-list">
        <router-link :to="{path: '/goodsDetail', query: {id: item.GoodsId}}" v-for="(item, index) in goodsList" :key="index" class="goods-item">
          <div class="img img-cover">
            <img v-if="item.GoodsThumb" :src="item.GoodsThumb" />
            <img v-else src="../../assets/images/no-image-icon.png" />
          </div>
          <div class="goods-info">
            <div class="name">{{ item.GoodsName }}</div>
            <div class="money">¥ <span>{{ item.GoodsPrice }}</span></div>
          </div>
          <div v-if="rightData.edit" class="delete-item">
            <div class="icon img-cover" @click.prevent="handleShowDelete(item, index)">
              <img src="../../assets/images/delete-icon.png" />
            </div>
          </div>
        </router-link>
      </div>
    </van-list>
    <van-popup v-model="deleteData.show" position="center" style="border-radius: .16rem;">
      <div class="mobile-delete-container">
        <div class="title">取消收藏</div>
        <div class="detail">确认要取消收藏吗？</div>
        <div class="btn-item">
          <div class="btn" @click="deleteData.show = false">取消</div>
          <div class="btn sure" @click="handleCollection(deleteData.data, deleteData.index)">
            <van-loading v-if="deleteData.submiting" color="#ffffff" />
            <span v-else>确定</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "Collection",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      rightData: {},
      PageCount: 10,
      Page: 1,
      loading: false,
      finish: false,
      goodsList: [],
      deleteData: {
        show: false,
        data: {},
        index: 0,
        submiting: false
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    this.rightData = {
      show: true,
      text: '管理',
      edit: false,
      callback: this.rightCallback
    }
  },
  methods: {
    rightCallback() {
      const { rightData } = this
      if (rightData.edit) {
        this.rightData.edit = false
        this.rightData.text = '管理'
      } else {
        this.rightData.edit = true
        this.rightData.text = '退出管理'
      }
    },
    async getGoodsCollectList() {
      const { PageCount, Page, goodsList } = this
      const params = {
        PageCount,
        Page
      }
      var res = await this.$API.goodsCollectList.post(params)
      this.goodsList = [
        ...goodsList,
        ...res.info
      ]
      this.Page = Page + 1
      this.finish = res.info.length < PageCount
      this.loading = false
    },
    handleShowDelete(item, index) {
      this.deleteData = {
        show: true,
        data: { ...item },
        index: index,
        submiting: false
      }
    },
    async handleCollection(item, index) {
      const { deleteData } = this
      if (deleteData.submiting) {
        return
      }
      this.deleteData.submiting = true
      var res = await this.$API.addGoodsCollect.post({
        GoodsId: item.GoodsId
      })
      this.deleteData.submiting = false
      this.deleteData.show = false
      if (res.error == 0) {
        this.$toast.success(res.info)
        this.goodsList.splice(index, 1)
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
      return false
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
}
.goods-list {
  margin: .24rem .32rem;
  padding: 0 .24rem;
  background-color: #fff;
  border-radius: .24rem;
  .goods-item {
    padding: .32rem 0 .36rem;
    border-bottom: .02rem solid #f7f7f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-last-child(1) {
      border-bottom: 0;
    }
    .img {
      width: 1.6rem;
      height: 1.6rem;
      border-radius: .16rem;
      overflow: hidden;
    }
    .goods-info {
      flex: 1;
      height: 1.6rem;
      margin-left: .24rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      overflow: hidden;
      color: #030304;
      .name {
        width: 100%;
        line-height: .4rem;
        font-size: .28rem;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .money {
        font-weight: bold;
        font-size: .24rem;
        line-height: .42rem;
        span {
          font-size: .3rem;
        }
      }
    }
    .delete-item {
      margin-left: .24rem;
      width: .36rem;
      .icon {
        width: .36rem;
        height: .36rem;
      }
    }
  }
}
.mobile-delete-container {
  width: 5rem;
  padding: .4rem;
  text-align: center;
  .title {
    line-height: .48rem;
    color: #11113a;
    font-size: .32rem;
    font-weight: bold;
  }
  .detail {
    margin-top: .28rem;
    color: #bcbdc5;
    line-height: .4rem;
    font-size: .28rem;
  }
  .btn-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: .72rem;
    .btn {
      cursor: pointer;
      width: 2.4rem;
      height: .8rem;
      border-radius: .12rem;
      background-color: #bac0cc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: .24rem;
      &.sure {
        background-color: #1399ff;
      }
    }
  }
}
</style>